import classNames from "classnames";
import { SanityMainContentCard } from "../../model/landing-page";
import BlockContent from "../BlockContent/BlockContent";
import Card from "../Card/Card";
import { Col, Row, Wrapper } from "../Grid";
import styles from "./LandingPageContent.module.scss";
import React from "react";

const contentCardLayouts = {
  "narrow-full-text": {
    col: {
      sm: 10,
      md: 8,
      lg: 7
    },
    content: {
      cap: "none" as const
    }
  },
  "wide-narrow-text": {
    col: {
      sm: 10,
      md: 8,
      lg: 8
    },
    content: {
      cap: "3/4" as const
    }
  }
};

const ContentCard = ({ value }: { value: SanityMainContentCard }) => {
  const layout = contentCardLayouts[value.layout ?? "wide-narrow-text"];
  return (
    <Wrapper className={classNames(styles.contentCard, styles.noPaddingMobile)}>
      <Row justify="center" className={styles.mainContentRow}>
        <Col {...layout.col}>
          <Card type="main" className={styles.infoCard}>
            <BlockContent content={value.content} {...layout.content} />
          </Card>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ContentCard;
