import classNames from "classnames";
import React, { ReactNode } from "react";
import BlockContent from "../../components/BlockContent/BlockContent";
import { Col, Row } from "../../components/Grid";
import TeachFeaturedCarousel from "../TeachFeaturedCarousel/TeachFeaturedCarousel";
import styles from "./TeachRow.module.scss";
import Card from "../../components/Card/Card";
import Text from "../Text/Text";
import { SanityRawContent } from "../../model/common";
import Link from "../../components/Link/Link";

interface TeachRowProps {
  title: string;
  subtitle?: string;
  content?: SanityRawContent;
  viewMoreText?: string;
  viewMoreURL?: string;
  filters?: ReactNode;
  showFilters?: boolean;
  cards: JSX.Element[];
}

const TeachRow = ({
  title,
  content,
  subtitle,
  viewMoreText,
  viewMoreURL,
  filters,
  showFilters,
  cards
}: TeachRowProps) => {
  return (
    <Row className={styles.eduPortalRow}>
      <Col className={classNames(styles.noPadding, styles.noMargin)}>
        <Card type="main" className={styles.card}>
          <Row className={styles.rowHeader} valign="middle">
            <Text variant="h2">{title}</Text>
            {viewMoreURL && (
              <Text variant="defaultLight">
                <Link to={viewMoreURL}>{viewMoreText ?? "View all"}</Link>
              </Text>
            )}
          </Row>
          {subtitle && (
            <Row className={styles.subtitle}>
              <Text variant="default">{subtitle}</Text>
            </Row>
          )}
          {filters && showFilters && (
            <Row className={styles.filters}>{filters}</Row>
          )}
          <TeachFeaturedCarousel
            carouselItems={cards}
            itemTypeMessage={title}
          />
          {content && (
            <Row className={styles.content}>
              <BlockContent content={content} />
            </Row>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default TeachRow;
